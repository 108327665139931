const validateNameInput: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.nameInput') || null;
    const name: string = input?.value || '';
    const isRequired: boolean = parent?.classList.contains('required');

    parent.classList.remove('invalid');

    if (isRequired) {
      const isValidName: boolean = getIsValidName(name);

      if (!isValidName) parent.classList.add('invalid');

      return;
    }

    if (name !== '') {
      const isValidName: boolean = getIsValidName(name);

      if (!isValidName) parent.classList.add('invalid');

      return;
    }
  } catch (error) {
    console.error(error);
  }
};

const clearNameInputError: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.nameInput') || null;

    parent.classList.remove('invalid');
  } catch (error) {
    console.error(error);
  }
};

const setNameInputError: (nameInputID: string) => void = nameInputID => {
  try {
    const nameInput: any = document.getElementById(nameInputID);
    const parent: any = nameInput?.closest('.nameInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const getIsValidName: (name: string) => boolean = name => {
  try {
    const regex: RegExp = /^[\p{L}\s-]{1,30}$/u;

    if (!regex.test(name) || name === '') return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const relocateNameInputValueByIdToNewInputByIdAndReturnSuccess: (nameInputID: string, newInputID: string) => boolean = (
  nameInputID,
  newInputID
) => {
  try {
    console.log(nameInputID, newInputID);
    console.log(document.getElementById(nameInputID), document.getElementById(newInputID));
    const nameInput: any = document.getElementById(nameInputID);
    const newInput: any = document.getElementById(newInputID);
    const nameInputValue: string = nameInput?.value || '';

    newInput.value = nameInputValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
